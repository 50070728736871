.login__div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  height: 100vh;
  background: linear-gradient(270deg, #6ab8ee, #193d3c);
  -webkit-animation: waves 5s ease infinite;
  -moz-animation: waves 5s ease infinite;
  background-size: 200%;
  animation: waves 5s ease infinite;
  padding-top: 4rem;
}
.login__card__content {
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: initial;
  text-align: center;
}

.login__desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login__desc img {
  width: 50px;
  margin-bottom: 12px;
}

.login__desc h6 {
  color: #fff;
}

.login__typography__h5 {
  margin-bottom: 1.5rem !important;
}

.login_paper {
  line-height: 2.4;
}

@-webkit-keyframes waves {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes waves {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes waves {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
